import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";


const LoginPage = React.lazy(() => import('./auth/login/login-page'));
const PasswordPage = React.lazy(() => import('./auth/login/password-page'));
const ForgotPasswordPage = React.lazy(() => import('./auth/forgot-password/forgot-password-page'));
const ChangePasswordPage = React.lazy(() => import('./auth/change-password/change-password-page'));
const PasswordChangedPage = React.lazy(() => import('./auth/password-changed/password-changed-page'));
const ChangePasswordEmailSentPage = React.lazy(() => import('./auth/change-password-email-sent/change-password-email-sent-page'));
const SignupWizard = React.lazy(() => import('./auth/signup/signup-wizard'));


export const AppContent = () =>
        <Suspense fallback={<h1>Loading</h1>}>
            <Router>
                <Switch>
                    <Route component={LoginPage} exact path="/login"/>
                    <Route component={PasswordPage} exact path="/password"/>
                    <Route component={SignupWizard} exact path="/signup"/>
                    <Route component={ForgotPasswordPage} exact path="/forgot-password"/>
                    <Route component={ChangePasswordPage} exact path="/change-password"/>
                    <Route component={PasswordChangedPage} exact path="/password-changed"/>
                    <Route component={ChangePasswordEmailSentPage} exact path="/forgot-password-email-sent/:email"/>
                </Switch>
            </Router>
        </Suspense>

