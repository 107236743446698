import sharedStyles from './mixins-and-variables.scss';
import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    typography: {
        useNextVariants: true,

        fontFamily: [
            'National 2 Test',
        ].join(','),
        color: sharedStyles['gray8']
    },

    palette: {
        primary: {
            light: '#7F8CFF',
            main: '#6b72e1',
            dark: '#293C89',
            contrastText: '#FFF',
        },
        secondary: {
            light: '#70E1FF',
            main: '#08CBFE',
            dark: '#03AFDC',
            contrastText: '#FFF',
        },
        error: {
            main: sharedStyles['error']
        },
        grey: {
            50: '#FAFAFA',
            100: '#F5F5F5',
            200: '', // we don't use this
            300: '#E1E1E1',
            400: '#BDBDBD',
            500: '#999999',
            600: '#757575',
            700: '#666666',
            800: '#333333',
            900: '' // we don't use this
        }
    },

    overrides: {
        h1: {
            fontWeight: 300
        },

        MuiButton: {
            root: {
                '&:not($containedPrimary)': {
                    color: sharedStyles['purple'],
                    backgroundColor: '#FFFFFF'
                },
                '&:not($containedPrimary):hover': {
                    backgroundColor: sharedStyles['gray1']
                }
            }
        },

        MuiFormControl: {
            root: {
                marginBottom: '30px',
                fontFamily: 'National 2 Test',
                fontWeight: 400,
                color: sharedStyles['gray8']
            }
        },
        MuiInputLabel: {
            root: {
                color: sharedStyles['gray4'],
                '&$focused': {
                    color: sharedStyles['gray4']
                },
                '&$error': {
                    color: sharedStyles['gray4']
                },
                '&$filled': {
                    transform: 'translate(20px, 22px) scale(1)'
                },
                '&$filled$shrink': {
                    transform: 'translate(20px, 12px) scale(0.75)'
                }
            }
        },
        MuiFormHelperText: {
            root: {
                fontSize: '0.9rem',
                '&$contained': {
                    margin: '12px 20px 0'
                }
            }
        },
        MuiFilledInput: {
            root: {
                '&:not(.default)': {
                    overflow: 'hidden',
                    backgroundColor: '#FFF'
                },
            }
        },
        MuiInputBase: {
            root: {
                '&:not(.default)': {
                    color: sharedStyles['gray8'],
                    transition: 'all 0.2s',
                    borderRadius: '10px',
                    backgroundColor: '#FFF',
                    border: '1px solid ' + sharedStyles['inputBorder']
                },
                '&:after': {
                    display: 'none'
                },
                '&:before': {
                    display: 'none'
                },
                '&$focused': {
                    boxShadow: `0 0 10px ${sharedStyles['blueLighter']}`,
                    border: '1px solid ' + sharedStyles['purpleLighter']
                },
                '&$error': {
                    border: '1px solid ' + sharedStyles['error']
                },
                '&$focused&$error': {
                    boxShadow: `0 0 10px ${sharedStyles['errorLight']}`,
                    border: '1px solid ' + sharedStyles['error']
                }
            },
            input: {
                '&:not(.default)': {
                    padding: '27px 12px 10px 20px'
                }
            }
        }
    }
});
