import React from 'react';
import './app.css';
import {theme} from "./theme";
import MuiThemeProvider from "@material-ui/core/es/styles/MuiThemeProvider";
import {AppContent} from "./app-content";


export const App = () =>
    <MuiThemeProvider theme={theme}>
        <AppContent/>
    </MuiThemeProvider>;

